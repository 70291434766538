<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack()">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10231')}}
							</h2>
						</div>
						<div class="inner__wrap">
							<div class="frms">
								<div class="frms__item">
									<h3 class="frms__title">{{t('10037')}}</h3>
									<div class="frms__wrap">
										<input type="tel" class="ipt-frm" :placeholder="t('10827')" :value="phoneNumber" @input="updatePhoneNumber($event)" @keyup="checkNumber($event)" maxlength="13" :disabled="isSended"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn-frmreset" v-if="phoneNumber.length > 0" @click="reset()" :disabled="isSended">
												<i class="icon icon-closesmall">{{t('10036')}}</i>
											</button>
											<button type="button" class="btn btn-medium btn-secondary" :class="{active: isPhoneNumber && (!isSended || phoneValidateTime === 0)}" 
											:disabled="!(isPhoneNumber && (!isSended || phoneValidateTime === 0))" @click="onClickSendPhoneNumber">{{t('10122')}}</button>
										</div>
									</div>
								</div>
								<div class="frms__item" v-if="isSended">
									<h3 class="frms__title">{{t('10124')}}</h3>
									<div class="frms__wrap" :class="{failed: isFailed}">
										<input type="text" pattern="[0-9]*" inputmode="numeric" class="ipt-frm" :placeholder="t('10779')" :value="verifyCode" @input="event => verifyCode = event.target.value"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<!-- 남은 시간 -->
											<span class="frms__time eng">
												{{String(Math.trunc(phoneValidateTime / 60)).padStart(2, "0")}}:{{String(phoneValidateTime % 60).padStart(2, "0")}}
											</span>
											<button type="button" class="btn btn-medium btn-secondary active" @click="reSend">{{t('10125')}}</button>
										</div>
									</div>
									<div class="frms__msg" v-if="isFailed">
										{{t('10126')}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<!-- [D] 찾기 불가 -->
		<div class="btns btns--centered">
			<button type="button" class="btn" :class="verifyCode.toString().length > 0 ? 'btn-confirm' : 'btn-disabled'" :disabled="verifyCode.toString().length === 0" @click="verify">{{t('10231')}}</button>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore} from "vuex"
import { computed, ref } from "vue"
import usePhoneValidate from "@/composables/usePhoneValidate";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {	
	components: {
		ErrorAlert,
	},
	setup: function(){
		const store = useStore();
		const router = useRouter();
		const phoneNumber = computed(() => store.state.mmb03.phoneNumber);
		const verifyCode = ref('');
		const isPhoneNumber = ref(false);
		const openErrorAlert = ref(false)
		const {
			phoneValidateTime,
			onClickSendPhoneNumber,
			serverPhoneValidateNumber
		} = usePhoneValidate("02");
		const isSended = ref(false);
		const isFailed = ref(false); 
		
		const updatePhoneNumber = (e) => {
			store.commit('mmb03/SET_PHONE_NUMBER', {phoneNumber: e.target.value});
		};

		const checkNumber = () => {
			// event = (event) ? event : window.event;
			// const charCode = (event.which) ? event.which : event.keyCode;
			// if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
			// 	event.preventDefault();
			// } else {
			// 	return true;
			// }
			const number = phoneNumber.value.replace(/[^0-9]/g, "");
			let phone = "";

			if(number.length < 4) { 
				phone = number; 
			}
			else if(number.length < 7) { 
				phone += number.substr(0, 3); 
				phone += "-"; phone += number.substr(3); 
			} 
			else if(number.length < 11) { 
				phone += number.substr(0, 3); 
				phone += "-"; phone += number.substr(3, 3); 
				phone += "-"; phone += number.substr(6); 
			} 
			else { 
				phone += number.substr(0, 3); 
				phone += "-"; 
				phone += number.substr(3, 4); 
				phone += "-"; 
				phone += number.substr(7); 
			}
			store.commit("mmb03/SET_PHONE_NUMBER", {phoneNumber: phone});

			const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
			if(regPhone.test(phone)) {
				isPhoneNumber.value = true;
			} else {
				isPhoneNumber.value = false;
			}
		}

		const reset = () => {
			store.commit("mmb03/SET_PHONE_NUMBER", {phoneNumber: ""});
			isPhoneNumber.value = false;
		}

		const verify = () => {
			if(serverPhoneValidateNumber.value !== verifyCode.value.toString()) {
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "인증 번호가 일치하지 않습니다. 다시 확인해주세요."});
				openErrorAlert.value = true;
				//alert("인증 번호가 일치하지 않습니다. 다시 확인해주세요.");
				isFailed.value = true;
				return;
			}
			if(phoneValidateTime.value === 0){
				//alert("입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요."});
				openErrorAlert.value = true;
				return;
			}
			router.push("/member/find/id/complete");
		}
		const { t }= useI18n() //번역필수 모듈

		return {
			phoneNumber,
			verifyCode,
			isPhoneNumber,
			checkNumber,
			reset,
			verify,
			updatePhoneNumber,
			phoneValidateTime,
			onClickSendPhoneNumber: async () => {
				try {
					await onClickSendPhoneNumber(phoneNumber.value);
					isSended.value = true;
				}catch(e) {
					isSended.value = false;
					console.error(e);
				}
			},
			isFailed,
			isSended,
			openErrorAlert,
			t,  //번역필수 모듈
		  i18n,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			goBack: () => {
				router.back();
			},
			reSend: () => {
				if(phoneValidateTime.value > 0){
					//alert("인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요."});
					openErrorAlert.value = true;
				}else {
					//alert("인증번호를 재발송 하였습니다.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다."});
					openErrorAlert.value = true;
				}
				onClickSendPhoneNumber(phoneNumber.value);
			}
		}
	}
}
</script>